import React, { useEffect, useState } from "react"

import { rhythm, scale } from "../utils/typography"
import { usePercentWindowScrolled } from "../hooks"

const Subscribe = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const percentScrolled = usePercentWindowScrolled()
  const [emailAddress, setEmailAddress] = useState()
  const [showing, setShowing] = useState(false)

  useEffect(() => {
    if (!showing && !hasSubmitted && percentScrolled > 50) {
      setShowing(true)
    }
  }, [showing, setShowing, percentScrolled])

  function serializeData(obj) {
    if ("string" == typeof obj) {
      return obj
    }
    return Object.keys(obj)
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      })
      .join("&")
  }

  const submit = () => {
    const data = {
      "mauticform[formId]": 1,
      "mauticform[email_address]": emailAddress,
    }

    fetch(`https://mautic.jken.cloud/form/submit?formId=1`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: serializeData(data),
    })
      .then(() => {
        setHasSubmitted(true)
        setTimeout(() => {
          setShowing(false)
        }, 3000)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        transform: `translateY(${showing ? 0 : 100}px)`,
        backgroundColor: "#f5f2f0",
        borderTop: "1px solid #DADADA",
        transition: "transform .2s ease-in-out",
        padding: `${rhythm(0.6)} 0`,
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(28),
          fontFamily: `Montserrat, sans-serif`,
        }}
      >
        {!hasSubmitted && (
          <>
            <div
              style={{
                ...scale(0.3),
                marginTop: 0,
              }}
            >
              Like what you're reading?
            </div>
            <input
              placeholder="Email Address"
              onChange={e => setEmailAddress(e.target.value)}
              style={{
                padding: `${rhythm(0.4)} ${rhythm(0.4)}`,
                borderRadius: "20px",
                border: "1px solid #DADADA",
                outline: 0,
                flexGrow: 1,
                margin: `0 ${rhythm(1)}`,
              }}
            />
            <button
              onClick={submit}
              style={{
                border: 0,
                outline: 0,
                textAlign: "center",
                backgroundColor: "#0088ff",
                padding: `${rhythm(0.4)} ${rhythm(0.4)}`,
                color: "white",
                borderRadius: "20px",
                border: "2px solid #0266bd",
                letterSpacing: 1,
              }}
            >
              Subscribe
            </button>
          </>
        )}
        {hasSubmitted && (
          <div
            style={{
              ...scale(0.3),
              marginTop: 0,
              textAlign: "center",
              width: "100%",
            }}
          >
            Thank you! You've been subscribed.
          </div>
        )}
      </div>
    </div>
  )
}

export default Subscribe
