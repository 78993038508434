import { useState, useEffect } from "react"

export default function usePercentWindowScrolled() {
  const [py, setPy] = useState(NaN)

  useEffect(() => {
    function getScrollPercent() {
      var h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight"
      return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    }
    function onScroll() {
      setPy(getScrollPercent())
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return py
}
